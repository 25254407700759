import React, { useState, Suspense, lazy } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import "./CSS/universal.css";
import LoadingScreen from "./Components/LoadingScreen";
import PrivateRoute from './PrivateRoute'; // Make sure this path is correct

const delayedLazy = (importFunc, delay) => {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve(importFunc());
    }, delay);
  });
};

const Home = lazy(() => delayedLazy(() => import("./Home"), 1500));
const Wallet = lazy(() => delayedLazy(() => import("./Wallet"), 1500));
const Settings = lazy(() => delayedLazy(() => import("./Settings"), 1500));
const Inbox = lazy(() => delayedLazy(() => import("./Inbox"), 1500));
const Login = lazy(() => delayedLazy(() => import("./Login"), 1500));
const Register = lazy(() => delayedLazy(() => import("./Register"), 1500));
const ForgotPassword = lazy(() => delayedLazy(() => import("./ForgotPassword"), 1500));

function App() {
  const [selectedPage, setSelectedPage] = useState("Dashboard");

  return (
    <BrowserRouter>
      <Suspense fallback={<LoadingScreen />}>
        <Routes>
          <Route
            path="/"
            element={<PrivateRoute element={() => <Home selectedPage={selectedPage} setSelectedPage={setSelectedPage} />} />}
          />
          <Route
            path="/Inbox"
            element={<PrivateRoute element={() => <Inbox selectedPage={selectedPage} setSelectedPage={setSelectedPage} />} />}
          />

          {/*
          <Route
            path="/Inbox"
            element={<Inbox selectedPage={selectedPage} setSelectedPage={setSelectedPage} />}
          />
          */}

          <Route
            path="/Wallet"
            element={<PrivateRoute element={() => <Wallet selectedPage={selectedPage} setSelectedPage={setSelectedPage} />} />}
          />
          <Route
            path="/Settings"
            element={<PrivateRoute element={() => <Settings selectedPage={selectedPage} setSelectedPage={setSelectedPage} />} />}
          />
          <Route
            path="/Login"
            element={<Login />}
          />
          <Route
            path="/Registeration"
            element={<Register />}
          />
          <Route
            path="/Forgot_Password"
            element={<ForgotPassword />}
          />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
